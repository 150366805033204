.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-left: -35px;
	margin-right: -35px;
	justify-content: space-between; /* Ensure even distribution of items */
}

.all-projects-project {
	width: calc(100% / 3 - 20px); /* Add spacing between cards */
	box-sizing: border-box;
	padding: 10px;
}

@media (max-width: 1024px) {
	.all-projects-project {
		width: calc(100% / 2 - 20px); /* 2 projects per row for tablets */
	}
}

@media (max-width: 600px) {
	.all-projects-project {
		width: calc(100% - 20px); /* 1 project per row for small screens */
	}
}