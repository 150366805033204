.certificates-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}

.certificates-title {
	font-size: 36px;
	font-weight: bold;
	padding-bottom: 40px;
}

.certificates-carousel {
    width: 90%; /* Adjust width to a percentage for smaller screens */
    margin: 0 auto; /* Center it horizontally */
}


.carousel .slide img {
	border-radius: 10px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.carousel .legend {
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	font-size: 18px;
	padding: 10px;
	border-radius: 5px;
}
@media (max-width: 600px) {

    .certificates-carousel {
        width: 95%; /* Make the carousel almost full width */
        margin: 0 auto;
    }
}
