@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 100%; /* Make the background full width */
	max-width: 800px; /* Set a max width */
	height: 50px; /* Adjust height for better aesthetics */
	padding-left: 0;
	padding-right: 0;
	background: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
	display: flex; /* Use flex to align items */
	justify-content: space-between; /* Space items evenly */
	align-items: center; /* Center items vertically */
	padding: 0 15px; /* Add padding for content spacing */
}

.nav-list {
	display: flex;
	justify-content: flex-start; /* Align items to the left */
	list-style: none;
	align-items: center;
	padding: 0; /* Reset padding */
	margin: 0; /* Reset margin */
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
	margin: 0 10px; /* Spacing between items */
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.language-switch {
	margin-left: auto; /* Push the language switch to the right */
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 100%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 50px; /* Adjust height for mobile */
	}

	.nav-item {
		font-weight: bold;
	}
}
