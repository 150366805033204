.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
	transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add smooth transitions */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff0000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.close-button:hover {
  background: #cc0000;
}

.project a {
	text-decoration: none;
}

.project:hover {
	background: #fafafa;
	opacity: 1;
}

.project-container {
	padding: 30px;
	display: flex;
	flex-direction: column; /* Ensure content is stacked vertically */
	height: 100%; /* Full height for flex alignment */
}

.project-logos {
	width: auto;
	display: flex;
	flex-direction: row;
	gap: 10px; /* Proper spacing between logos */
	justify-content: center; /* Center the logos */
}

.project-logos img {
	width: 30px; /* Set a fixed width for logos */
	height: auto; /* Maintain aspect ratio */
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
	text-align: center; /* Center-align the title */
}

.project-description {
	color: var(--secondary-color);
	text-align: center; /* Center-align the description */
}

.project-links {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
	justify-content: center; /* Center-align the links */
	gap: 15px;
}

.project-link-icon {
	font-size: 13px;
}

.project-link-text {
	font-weight: 700;
}

.project-link-text:hover {
	color: var(--link-color);
	transition: color 0.1s ease-in-out, font-weight 0.1s ease-in-out;
}

@media (max-width: 600px) {
	.project {
		height: auto; /* Allow height to be dynamic on smaller screens */
	}
}